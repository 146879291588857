import { CSSProperties } from 'react'
import type { GetStaticProps } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import {
  Box,
  Button,
  Typography,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core'
import { NavigateNextRounded } from '@material-ui/icons'
import { light } from '@material-ui/core/styles/createPalette'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import LadderCardList from '../components/LadderCardList'
import { getFullUrl } from '../utils'
import { getPlayerLadders, getTrendingPlayerLadderInfo } from '../api/server'
import { PlayerLadder, PlayerTier } from '../types'
import LadderCard from '../components/LadderCard'

const useMastheadStyles = makeStyles(({ spacing }) => {
  const fullBleed: CSSProperties = {
    left: 0,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  }

  return createStyles({
    root: {
      height: '100vh',
      overflow: 'hidden',
      position: 'relative',
    },
    image: {
      ...fullBleed,
      objectFit: 'cover',
      objectPosition: 'center center',
    },
    content: {
      ...fullBleed,
      alignItems: 'center',
      color: light.text.primary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: spacing(3, 3, '10vh'),
      textAlign: 'center',
    },
  })
})

const Masthead = () => {
  const classes = useMastheadStyles()
  const { breakpoints } = useTheme()
  const imageSizes = [
    breakpoints.values.sm,
    breakpoints.values.md,
    breakpoints.values.lg,
    breakpoints.values.xl,
    2880,
    3840,
    5000,
  ]
  const srcSet = imageSizes
    .map((pixels) => `/home/basketball-hoop-${pixels}.jpg ${pixels}w`)
    .join(', ')
  const linkProps = {
    imagesrcset: srcSet,
    imageSizes: '100vw',
  }

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href="/home/basketball-hoop-1920.jpg"
          {...linkProps}
        />
      </Head>
      <Box className={classes.root}>
        <img
          src="/home/basketball-hoop-1920.jpg"
          srcSet={srcSet}
          sizes="100vw"
          alt="Black Metal basketball hoop. By MontyLov (https://unsplash.com/@montylov) on Unsplash."
          className={classes.image}
        />

        <Box className={classes.content}>
          <Typography variant="h4" component="h1" gutterBottom color="inherit">
            How do you rank the best players in the NBA?
          </Typography>

          <Link href="/create" passHref>
            <Button color="primary" size="large" variant="contained">
              Pick your top 25
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  )
}

interface Props {
  recentLadders: PlayerLadder[]
  trendingTiers: PlayerTier[]
}

const HomePage = ({ recentLadders, trendingTiers }: Props) => {
  const url = getFullUrl('/')

  return (
    <Layout hideHeader masthead={<Masthead />}>
      <Meta url={url} />

      <Box mt="2" mx="auto" maxWidth="600px">
        <LadderCard
          ladder={{
            id: 'trending',
            created: '2021-04-22T04:08:53.672Z',
            modified: new Date().toISOString(),
            tiers: trendingTiers,
            title: 'Trending NBA Top 25',
          }}
          url="/l/trending"
        />
      </Box>

      {recentLadders?.length > 0 && (
        <Box mt={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              Recent ladders
            </Typography>
            <Link href="/l/recent" passHref>
              <Button
                endIcon={<NavigateNextRounded />}
                aria-label="View more recent ladders"
              >
                View more
              </Button>
            </Link>
          </Box>
          <LadderCardList ladders={recentLadders} />
        </Box>
      )}
    </Layout>
  )
}

export default HomePage

const ONE_HOUR = 60 * 60

export const getStaticProps: GetStaticProps = async () => {
  const recentLadders = await getPlayerLadders(4)
  const { tiers: trendingTiers } = await getTrendingPlayerLadderInfo()

  return {
    props: {
      recentLadders,
      trendingTiers,
    },
    revalidate: ONE_HOUR,
  }
}
